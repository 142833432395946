.report--table {
  .red {
    background-color: rgb(252, 108, 108);
  }
}

.report--table {
  & + .report--table {
    margin-top: 20px;
  }
  .report--table__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: 600;
      font-size: 1.3em;
    }
  }

  .wrapper {
    margin: 20px;
    background-color: #FFF;
    border-radius: 4px;
    .image--preview {
      max-width: 120px;
      height: auto;
      width: 100%;
    }
  }
}


.report.widgets--report {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  .form {
    width: 100%;
    background-color: #FFF;
    padding: 12px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow:  0 0 3px rgba(0, 0, 0, 0.3);
  }
  .widgets--list {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    .widget--item {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1 33%;
      margin-bottom: 12px;
      .label {
        padding-left: 12px;
        padding-right: 8px;
      }
    }
  }
  .widgets--list {
    .widget--list_item {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #FFF;
      margin-top: 10px;
      padding: 12px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      box-shadow:  0 0 3px rgba(0, 0, 0, 0.3);
    }
  }
  .chart {
    background-color: #FFF;
    margin-top: 10px;
    padding: 12px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    box-shadow:  0 0 3px rgba(0, 0, 0, 0.3);
  }
}


.report.budget {
  background-color: #FFF;
  margin: 12px;
  border-radius: 4px;
  box-shadow:  0 0 3px rgba(0, 0, 0, 0.3);
  .actions {
    margin-left: 12px;
  }
}

.budget.upload {
  background-color: #FFF;
  margin: 12px;
  border-radius: 4px;
  box-shadow:  0 0 3px rgba(0, 0, 0, 0.3);
}
