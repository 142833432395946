.docs--widget {
  display: flex;
  flex-direction: row;
  width: 100%;
  .docs--container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 12px;
    .docs--search_filter {
      margin-bottom: 12px;
    }
  }
}
