.groups--list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  .filter {
    background-color: #FFF;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > div + div {
      margin-left: 8px;
    }
    .search--column {
      width: 100%;
    }
  }
  .ant-spin-container{
    padding: 0 14px;
    > div {
      background-color: #FFF;
    }
  }
}
