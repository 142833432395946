.document--lock {
  display: flex;
  flex-direction: row;
  background-color: #FFF;
  align-items: center;
  justify-content: space-between;
  margin: 12px;
  padding: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}
