.group-add {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  > .groups--form {
    background-color: #FFF;
    border-radius: 4px;
    padding: 8px;
  }
}
