.preview--container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin: 20px auto;
  background-color: #FFF;
  padding: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  .preview--summary {
    margin-bottom: 20px;
  }
  > img {
    width: 100%;
    height: auto;
    border: 0;
    margin-bottom: 20px;
  }
  .preview--cover{
    width: 100%;
    margin-bottom: 20px;
    display: flex;
  }
  .preview--block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    &.external {
      iframe {

      }
    }
    &.video, &.image {
      flex-direction: column;
      font-size: 0;
      img, video {
        width: 100%;
        height: auto;
      }
      .info {
        background-color: #e2e2e2;
        padding: 12px;
        font-size: 14px;
      }
    }
  }
}
