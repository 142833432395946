.item {
  display: flex;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-bottom: 12px;
  min-width: 23%;
  max-width: 23%;
  transition: boxShadow 0s ease 0.3s;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0);
  position: relative;
  .photo {
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #dfdfdf;
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      top: 0;
      left: 0;
      transition: backgroundColor 0s ease 0s;
    }
  }



  .info {
    font-size: 12px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .actions {
    z-index: 2;
    position: absolute;
    top: -10px;
    right: 0;
    display: flex;
    flex-direction: row;
    padding: 8px;
    opacity: 0;
    transition: all 0.3s ease 0s;
    > div {
      + div {
        margin-left: 4px;
      }
    }
  }
}

.item:hover {
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.4);
  .photo {
    &:after{
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .actions {
    top: 0;
    opacity: 1;
  }
}

.video-play {
  overflow: hidden;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 24%;
  padding-bottom: 24%;
  text-decoration: none;
  position: relative;
  display: inline-block;
}
