.uploader {
  display: flex;
  flex-direction: column;
  width: 100%;
  .uploader--options {
    padding: 8px;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .uploader--container {
    padding: 8px;
  }
}
