.project_rbth {
  .logo {
    background-color: transparent;
    background-image: url('/images/logos/win.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.project_fintweet, .project_atel {
  .logo {
    background-color: transparent;
    background-image: url('https://cdnen.rt.com/static/img/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}