.chat--container {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  background-color: #FFF;
  margin: 12px;
  border-radius: 12px;
  .chat--header {
    font-weight: 600;
    padding: 12px;
    border-bottom: 1px solid #dfdfdf;
  }
  .chat--messages_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    > div {
      border-bottom: 1px solid #dfdfdf;
    }
  }
}

.message--item {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  &:target {
    background-color: #7ce681;
  }
  &.deleted {
    background-color: #ed5353;
  }
  & + .message--item {
    margin-bottom: 20px;
  }
  > .message--column {
    display: flex;
    flex-direction: column;
    flex: 1;
    &.avatar {
      width: 80px;
      max-width: 80px;
    }
    &.info {
      flex-direction: row;
      align-items: center;
    }
  }
  > .info {
    padding-left: 20px;
    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
      &.actions{
        max-width: 48px;
        padding: 0 8px;
        > button {
          & + button {
            margin-top: 10px;
          }
        }
      }
    }
    .user--name {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      .name {
        color: #020202;
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }

  .message-reply {
    margin: 10px;
    background-color: #e2e2e2;
    padding: 12px;
    cursor: pointer;
    border-left: 4px solid #00152D;
    .user--name {
      color: #020202;
      font-weight: 600;
      margin-right: 10px;
    }
  }
}


.chat--table {
  .deleted {
    background-color: rgba(255, 0, 0, 0.3);
  }
}
