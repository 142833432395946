.cross_link__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  .list__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 4px;
    justify-content: space-between;
    & + .list__item {
      border-top: 1px solid #dfdfdf;
    }
    .list__item-actions {
      margin-left: 20px;
    }
  }
}
