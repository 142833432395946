.form--group.cover {
  .media_selector.cover__selector {
    padding: 0;
  }
  .form--group__title{
    margin-bottom: 0;
  }
  .form--group__container {
    padding: 0;
  }
}


.media--container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .media--filter {
    padding: 8px;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .filter {
      border-left: 1px solid #dfdfdf;
      margin-left: 12px;
      padding-left: 12px;
    }
  }

  .media--list__pager {
    border-top: 1px solid #dfdfdf;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 8px;
  }
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 360px;
  line-height: 360px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
