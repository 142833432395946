.textarea {
  display: flex;
  flex-direction: row;
  textarea {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  .counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 4px;
  }
}
