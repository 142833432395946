.events {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .event {
    display: flex;
    flex-direction: row;
    align-items: center;
    & + .event {
      border-top: 1px solid #dfdfdf;
    }
  }
}
