.list--card {
  margin-bottom: 12px;
  .header--authors {
    a {
      border-right: 1px solid #dfdfdf;
      padding-left: 4px;
      padding-right: 4px;
      margin-right: 4px;
      &:last-child {
        border-right: 0;
      }
    }
  }
  &.card--default {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #FFF;
    border-radius: 4px;
    margin-top: 12px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    &.card--vm {
      margin-top: 0;
      &:first-child{
        margin-top: 12px;
      }
    }
    .card--actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-left: 1px solid #dfdfdf;
      > button + button{
        margin-top: 12px;
      }
    }
    .card--info{
      display: flex;
      flex-direction: column;
      width: 100%;
      .header, .footer {
        padding: 4px;
      }
      .header {
        border-bottom: 1px solid #dfdfdf;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .header--info {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .header--status {
          display: flex;
          flex-direction: row;
          align-items: center;
          .date {
            margin-right: 10px;
          }
        }

      }
      .footer {
        border-top: 1px solid #dfdfdf;
      }
      .content {
        display: flex;
        flex-direction: row;
        padding: 8px;
        .cover {
          display: flex;
          flex-direction: column;
          width: 240px;
          img {
            width: 100%;
            height: auto;
            border: 0;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 12px;
          .title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 8px;
          }
        }
      }
    }
    &.card--vm {
      .header--info {
        font-size: 13px;
      }
      .header--status {
        font-size: 13px;
      }
      .card--info {
        .content {
          .cover {
            width: 140px;
          }
          .info {
            .title {
              font-size: 16px;
              line-height: 16 * 1.3px;
            }
            .info--row {
              font-size: 13px;
              line-height: 13 * 1.3px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              > div {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .footer--row {
      display: flex;
      flex-direction: row;
      width: 100%;
      &.card--dates {
        div + div {
          margin-left: 12px;
        }
      }
      + .footer--row {
        margin-top: 6px;
      }
      &.footer--props {

      }
      .card--prop {
        display: flex;
        flex-direction: row;
        .ant-tag {
          display: flex;
          flex-direction: row;
          align-items: center;
          > i {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
