.ant-layout.ant-layout-has-sider > .ant-layout{
  overflow-x: visible;
}
.app_view {
  display: flex;
  //flex-direction: column;
  width: 100%;
  flex-direction: row-reverse;

  .app_view--list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    .list {
      .ant-empty {
        margin: 20px auto;
      }
    }
  }
  .app_view--filter {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    width: 100%;
    margin-right: 12px;
    padding: 12px;
    position: relative;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }
}
