.history {
  .history--wrapper{
    max-height: 240px;
    overflow-y: auto;
    padding-top: 10px;
  }
}


.container--diff {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding-right: 20px;
    & + div {
      padding-right: 0;
      padding-left: 20px;
      border-left: 1px solid #dfdfdf;

      .changed {
        width: 100%;
        background-color: #dfdfdf;
        display: inline-block;
        padding: 8px;
        border-radius: 4px;
        margin-bottom: 20px;
      }
    }
    .cover {
      width: 100%;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-weight: 600;
      font-size: 2em;
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 12px;
    }
    .text--title {
      font-size: 1.6em;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .slug {
      margin: 4px 0;
    }
    .options {
      background-color: #dfdfdf;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      margin: 4px 0;
      padding: 4px;
      > div {
        border-bottom: 1px solid #e2e2e2;
        padding: 4px 0;
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }
  .preview--block {
    width: 100%;
    margin: 12px 0;
    > * {
      width: 100%;
    }
  }
}
