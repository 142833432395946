.header--wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .ant-page-header {
    background-color: rgba(255, 255, 255, 0);
    padding-left: 0;
    span {
      color: #FFF;
    }
    .ant-page-header-back-button {
      color: #FFF;
    }
  }
  .header--user {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
    > div + div {
      margin-left: 12px;
    }
    .user--name {
      color: rgba(255, 255, 255, 0.65);;
    }
  }
}
