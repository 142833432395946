.permissions--list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  .ant-table {
    background-color: #FFF;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .filter--permissions {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    > div + div {
      margin-left: 8px;
    }
    .search--column {
      width: 100%;
    }
  }
}
