.editor {
  position: relative;
  .editor--hidden {
    display: none;
  }
}

.tox-statusbar__branding {
  opacity: 0;
}
