.quest__form--wrapper {
  display: block;
  .form__wrapper {
    display: BLOCK;
    background-color: #DFDFDF;
    padding: 12px;
    width: 100%;
    border-radius: 4px;
    margin-top: 20px;
  }
}