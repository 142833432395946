.workflow {
  display: flex;
  flex-direction: column;
  width: 100%;

}

.dashboard-workflow {

  .table--pagination {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin: 10px 0;
  }

  .table-cover {
    max-width: 120px;
    img {
      max-width: 120px;
    }
  }

  .ant-table-thead > tr > th {
    font-size: 13px;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    font-size: 12px;
    position: relative;
  }

  .wwstatus {
    width: 100%;
    height: 100%;
    background-color: #f5222d;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.workflow--table__statuses {
  display: flex;
  flex-direction: row;
  align-items: center;
  .statuses--item {
    display: flex;
    flex-direction: row;
    align-items: center;
    + div {
      margin-left: 20px;
    }
    > div {
      width: 20px;
      height: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
  }
}

.ant-table-bordered .ant-table-tbody > tr > td.workflow_status--column {
  text-align: center;
  .ant-tag {
    width: 20px;
    height: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    margin-right: 0;
  }
}

.workflow--table {
  .ant-table-bordered .ant-table-tbody > tr > td {
    position: relative;
    .status_worflow_table {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 85, 0, 1);
      position: absolute;
      top: 0;
      left: 0;
      &.green {
        background-color: #87d068;
      }
    }
  }
}

.workflow--title {
  display: inline-block;
  flex-direction: row;
  width: 100%;
  .lock {
    margin-right: 8px;
    display: inline-block;
  }
}

.dashboard--table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .filter--checkbox {
    margin-left: 10px;
  }

  .table--filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    .filter--status {
      display: flex;
      flex-direction: row;
      align-items: center;
      .status--item {
        & + .status--item {
          margin-left: 10px;
        }
      }
      span {
        padding-left: 4px;
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    tr {
      &:nth-child(odd) {
        background-color: rgba(226, 226, 226, 0.5);
      }
      td {
        border: 1px solid #dfdfdf;
        padding: 4px 6px;
        font-size: 12px;
        text-align: center;
        &.wf-status {
          width: 30px;
          align-items: center;
          text-align: center;
        }
        &.created {
          width: 130px;
          text-align: center;
        }
        &.title {
          //width: 400px;
          text-align: left;
          > * {
            display: inline;
            &.lock {
              margin-right: 4px;
            }
          }
        }

        &.authors {
          width: 120px;
        }

        &.status {
          width: 30px;
          align-items: center;
          text-align: center;
          i {
            svg {
              fill: #34c402;
            }
          }
          &.bad {
            i {
              svg {
                fill: #ff3d00;
              }
            }
          }
        }

        &.user-created {
          width: 130px;
        }
        &.cover {
          width: 80px;
          padding: 0;
          .lightbox-img-thumbnail {
            margin: 0;

          }
          img {
            width: 80px !important;
            height: auto !important;
          }
        }
        img {
          max-width: 80px;
        }
      }
    }
  }
}
