.docs--widget_item {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  background-color: #FFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  .cover{
    display: flex;
    flex-direction: column;
    max-width: 140px;
    min-width: 140px;
    margin-right: 12px;
    img {
      width: 100%;
      height: auto;
      border: 0;
    }
  }
  .options {
    display: flex;
    flex-direction: row;
    width: 100%;
    > div {
      display: flex;
      &.info {
        flex: 1;
        flex-direction: column;
      }
    }
    .title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 4px;
      line-height: 16 * 1.3px;
      .ant-tag {
        margin-right: 0;
      }
    }
    .summary {
      font-size: 14px;
      line-height: 14 * 1.3px;
      margin-bottom: 4px;
    }

    .published {
      margin-bottom: 4px;
    }

    .actions {
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > button {
        & + button {
          margin-top: 8px;
        }
      }
    }
  }
}
