#root {
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
    &.app_loading{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.app_header {
  padding: 0;
}

.container {
  width: 100%;
  &.container__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
  }
}

.app_loading_nflex{
  width: 100%;
  height: 100%;
}

.app_view {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.profile__avatar {
  width: 160px;
  height: 160px;
  -webkit-border-radius: 160px;
  -moz-border-radius: 160px;
  border-radius: 160px;
  background-repeat: no-repeat;
  background-size: cover;
}

[for="upload_form_properties.copyright"], [for="edit_form_properties.copyright"] {
  font-weight: 600;
}


.card--requests {
  background: #FFF;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  .header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0 4px 8px;
  }
  .card__block__info {
    display: flex;
    flex-direction: row;
    .card--column {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 12px;
      span {
        font-weight: bold;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      padding: 8px;
      border-left: 1px solid rgba(0, 0, 0, 0.15);
      button + button {
        margin-top: 8px;
      }
    }
  }
}

.profile_block {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  .profile_block--row {
    padding: 4px 8px;
    span:first-child {
      font-weight: 600;
    }
    span + span {
      margin-left: 12px;
    }
  }
  .profile_block--row + .profile_block--row {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
}