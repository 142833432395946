.app_view_table {
  display: flex;
  flex-direction: column;
  padding: 12px;
  .app_view--wrapper {
    background-color: #FFF;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 8px;
  }
  .app--filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div + div {
      margin-left: 8px;
    }
    > .search--col {
      width: 100%;
    }
  }
}
