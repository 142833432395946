.app_add--view {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  .app_add--wrapper {
    background: #FFF;
    padding: 8px;
    border-radius: 4px;
  }
}
