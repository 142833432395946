.requests {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px 24px;
  max-width: 1366px;

  &.request_study {
    grid-template-columns: 1fr;
  }

  .request {
    background: #FFF;
    border-radius: 6px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
  .request--header {
    padding: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .request--content {
    padding: 12px;
    label {
      font-weight: 600;
    }
    ul {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

}

.requests--wrapper {
  .requests-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

.requests__controller {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  .request--search {
    margin-bottom: 20px;
  }
}