.doc--status {
  display: flex;
  flex-direction: column;
  .doc--status_row {
    display: block;
    &.status{
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        margin-right: 4px;
      }
    }
    & + div {
      margin-top: 8px;
    }
  }
}
