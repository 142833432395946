.widget_view {
  background-color: #FFF;
  padding: 12px;
  .widget--search {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    > button {
      margin-right: 12px;
    }
  }
}

.widget_view_edit-actions {
  padding: 0 12px;
}
