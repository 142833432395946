.code--list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  max-height: 600px;
  overflow: auto;
  .code--list_item {
    display: flex;
    flex-direction: column;
    border: 1px solid #dfdfdf;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    & + .code--list_item {
      margin-top: 10px;
    }
    .code--preview {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 8px;
      overflow-y: auto;
    }
    .code--actions {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      border-bottom: 1px solid #dfdfdf;
      padding: 8px;
      button {
        & + button {
          margin-left: 8px;
        }
      }
    }
  }
}
