.media-input {
  margin-bottom: 24px;
}

.upload--input__form {
  display: flex;
  flex-direction: row;
  .form--preview {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    min-width: 320px;
    img {
      width: 100%;
      height: auto;
      border: 0;
    }
    video {
      width: 100%;
      max-height: 320px;
    }
    iframe {
      width: 100%;
      height: auto;
    }
  }
  .form{
    border-left: 1px solid #dfdfdf;
    margin-left: 12px;
    padding: 0 12px;
    width: 100%;
  }
}


.pdf--selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e2e2e2;
  padding: 10px;
  > div {
    flex: 1;
    flex-direction: row;
    &:last-child {
      text-align: right;
      justify-content: flex-end;
    }
  }
}
