.media--list__items {
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-empty {
    margin: 12px 0;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 12px 12px 0 12px;
  }
}
